:root {
    --multiplier: 1;
}

@media only screen and (max-width: 800px) {
    :root {
        --multiplier: 0.8;
    }
}

@media only screen and (max-width: 550px) {
    :root {
        --multiplier: 0.55;
    }
}

@media only screen and (max-width: 350px) {
    :root {
        --multiplier: 0.35;
    }
}

:root {
    --font-weight-bold: 700;
    --font-weight-normal: 400;

    --font-size-s: calc(var(--multiplier) * 26px);
    --font-size-l: calc(var(--multiplier) * 34px);
    --font-size-xl: calc(var(--multiplier) * 40px);
    --font-size-xxl: calc(var(--multiplier) * 50px);
    --font-size-xxxl: calc(var(--multiplier) * 70px);
    --grid-size-q: calc(var(--grid-size-1) * 0.25);
    --grid-size-h: calc(var(--grid-size-1) * 0.5);
    --grid-size-1: calc(var(--multiplier) * 8px);
    --grid-size-1-h: calc(var(--grid-size-1) * 1.5);
    --grid-size-2: calc(var(--grid-size-1) * 2);
    --grid-size-3: calc(var(--grid-size-1) * 3);
    --grid-size-4: calc(var(--grid-size-1) * 4);
    --grid-size-5: calc(var(--grid-size-1) * 5);
    --grid-size-6: calc(var(--grid-size-1) * 6);
    --grid-size-8: calc(var(--grid-size-1) * 8);
    --grid-size-10: calc(var(--grid-size-1) * 10);
    --grid-size-13: calc(var(--grid-size-1) * 13);
}

.dark-theme {
    --primary-color: #00203F;
    --secondary-color: #ADF0D1;
}

.light-theme {
    --primary-color: #ADF0D1;
    --secondary-color: #00203F;
}

.animated-transition {
    -moz-transition: all 300ms linear;
    -webkit-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.fill-window {
    min-height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: "Roboto Condensed", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    font-weight: var(--font-weight-normal);
}

.no-selection {
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -o-user-select: none;
    -moz-user-select: none;
}

::-moz-selection {
    color: var(--primary-color);
    background: var(--secondary-color);
}

::selection {
    color: var(--primary-color);
    background: var(--secondary-color);
}

.app {
    display: flex;
    color: var(--secondary-color);
    background: var(--primary-color);
    text-align: center;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 auto;
    margin-top: var(--grid-size-4);
}

.bold {
    font-weight: var(--font-weight-bold);
}

.button {
    background: transparent;
    color: var(--secondary-color);
    border: none;
    padding: var(--grid-size-3);
    cursor: pointer;
    font-family: inherit;
    font-size: var(--font-size-s);
}

.button:focus {
    outline: none;
}

h1 {
    font-size: var(--font-size-xxl);
    margin: var(--grid-size-4) var(--grid-size-2);
}

.score_items_wrapper {
    display: flex;
    align-items: flex-end;
}

.score_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.score_delimiter {
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-bold);
    padding-bottom: var(--grid-size-h);
}

.score_item__value {
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-bold);
    margin: 0px var(--grid-size-5);
}

.score_item__label {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-bold);
}

.statistic_item {
    display: flex;
    align-items: flex-end;
    margin-top: var(--grid-size-2);
}

.statistic_item__value {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-bold);
}

.statistic_item__text {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-normal);
    margin-left: var(--grid-size-1-h);
    margin-bottom: var(--grid-size-q);
}

.statistic_items_list {
    margin: var(--grid-size-6) auto;
}

.loading_text {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-bold);
}

.header-buttons {
    display: flex;
    justify-content: space-between;
}

.app__subtitle {
    font-size: var(--font-size-l);
    margin-top: var(--grid-size-6);
    opacity: 0.5;
    font-weight: var(--font-weight-normal);
    margin-bottom: 0;
}

.app__title {
    margin-top: var(--grid-size-1);
    margin-bottom: var(--grid-size-8);
}